import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormComponent} from '../form/form.component';
import {ControlAvatar} from '../../../core/form/controls';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpService} from '../../../core/http/http.service';

@Component({
    selector: 'my-goods-specs',
    templateUrl: './goods-specs.component.html',
    styleUrls: ['./goods-specs.component.css'],
})
export class GoodsSpecsComponent implements OnInit {

    @Input() imageUploadUrl: string = 'admin/common/file_upload';
    @Input() values: {
        name: string,
        unit: string,
        sale_price: number,
        bid_price: number,
        inventory: number,
        coding: string,
        barcode: string,
        image: string,
        default: boolean
    }[] = [];
    @Output() valuesChange: EventEmitter<any> = new EventEmitter();
    @Input() readonly: boolean = false;

    showUpdateModal = false;
    showUpdateData = {
        title: '',
        key: null,
        value: null
    };

    constructor(
        private msg: NzMessageService,
        private modalService: NzModalService,
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpService
    ) {
    }

    ngOnInit() {
    }

    onShowUpload(value: any = null) {
        const modal = this.modalService.create({
            nzTitle: '上传图片',
            nzContent: FormComponent,
            nzMaskClosable: false,
            nzComponentParams: {
                controls: [
                    new ControlAvatar({
                        name: 'image',
                        placeholder: '图片',
                        url: this.imageUploadUrl,
                        itemClass: 'p-20',
                        span: 24
                    })
                ],
                customSubmit: true
            },
            nzFooter: [{
                label: '确定',
                onClick: componentInstance => {
                    componentInstance.onSubmit.subscribe(e => {
                        if (e && e['image']) {
                            if (value) {
                                value.image = this.http.url(e['image'], false);
                            } else {
                                this.values = this.values.map(value => {
                                    value.image = this.http.url(e['image'], false);
                                    return value;
                                });
                            }
                            this.change();
                        }
                        modal.close();
                    });
                    componentInstance.submit();
                }
            }]
        });
    }

    onAdd() {
        this.values.push({
            name: '规格' + (this.values.length + 1),
            unit: '份',
            sale_price: 0,
            bid_price: 0,
            inventory: 9999,
            coding: '',
            barcode: '',
            image: '',
            default: false
        });
    }

    onDelete(i) {
        if (this.values.length > 1) {
            this.values.splice(i, 1);
        }
    }

    onShowSpecUpdateModal(title, key) {
        if (this.values.length) {
            this.showUpdateModal = !this.showUpdateModal;
            this.showUpdateData = {
                title: title,
                key: key,
                value: null
            };
        }
    }

    onSpecUpdateModalOk() {
        this.showUpdateModal = false;
        this.values = this.values.map(value => {
            value[this.showUpdateData.key] = this.showUpdateData.value;
            return value;
        });
        this.change();
    }

    onSpecUpdateModalCancel() {
        this.showUpdateModal = false;
    }

    defaultChange(value) {
        this.values.forEach((item: any) => {
            item.default = item.name === value.name;
        });
        this.change();
    }

    change() {
        this.valuesChange.emit(this.values);
    }
}
