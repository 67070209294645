// Angular
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ConfigService} from '../../../core/base/services/config.service';

@Component({
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit {

    isCollapsed = false;

    base: any = {};

    constructor(
        private cs: ConfigService
    ) {
        this.base = cs.getBase();
    }

    ngOnInit() {
    }
}
