// Angular
import {Injectable} from '@angular/core';
import {ViewBase} from './views';

@Injectable()
export class ViewService {

    constructor() {
    }

    build(views: ViewBase<any>[] = []): ViewBase<any>[] {
        return this.buildViews(views);
    }

    private buildViews(views: ViewBase<any>[]): ViewBase<any>[] {
        return views;
    }
}
