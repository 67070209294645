import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {moveItemInArray, CdkDragDrop} from '@angular/cdk/drag-drop';
import {catchError, map} from 'rxjs/operators';
import {HttpService} from '../../../core/http/http.service';
import {NzMessageService} from 'ng-zorro-antd';
import {of} from 'rxjs';
import {TableService} from '../../../core/table/table.service';
import {ColumnBase} from '../../../core/table/columns';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
    selector: 'my-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TableComponent implements OnInit {

    @Input() url: string = '';
    @Input() params: {} = {};

    @Input() set columns(_controls: ColumnBase[]) {
        this._columns = _controls;
        this.buildColumns();
    };

    @Input() autoLoad = true;
    @Output() onLoad = new EventEmitter();
    @Output() onChecked = new EventEmitter();
    @Output() onSorted = new EventEmitter();
    @Output() onDragSort = new EventEmitter();

    // 加载效果
    loading = false;

    // 表头
    _columns: ColumnBase[] = [];

    // 数据集
    dataSet = [];

    // 记录总数
    total = 0;

    // 多列排序对象
    sorts = {};

    // 查询字符串
    queryParams = {
        page: 1,
        limit: 10
    };

    // 行选择器
    rowSelector = {
        checkedAll: false,
        isIndeterminate: false,
        mapOfCheckedId: {},
        selectedIds: [],
        selectedTotal: 0
    };

    // 外部传入查询参数记录（用于分页）
    _params = {};

    constructor(
        private ps: NgxPermissionsService,
        private cdr: ChangeDetectorRef,
        private http: HttpService,
        private msg: NzMessageService,
        private ts: TableService
    ) {
    }

    ngOnInit(): void {
        if (false != this.autoLoad) {
            this.load();
        }
        if (!this.params) {
            this.params = {};
        }
    }

    buildColumns() {
        this._columns.forEach((column, index) => {
            if (column['buttons']) {
                column['buttons'].forEach((button, i) => {
                    if (undefined != button['permission']) {
                        this.ps.hasPermission(button['permission']).then(v => {
                            if (!v) {
                                this._columns[index]['buttons'].splice(i, 1);
                            }
                        });
                    }
                });
            }
        });
    }

    reset() {
        this.dataSet = [];
        this.total = 0;
        this.rowSelector = {
            checkedAll: false,
            isIndeterminate: false,
            mapOfCheckedId: {},
            selectedIds: [],
            selectedTotal: 0
        };
    }

    load(params = {}, isPage = false) {
        if (this.loading) {
            return;
        }

        this.loading = true;

        this._params = params;

        // 删除外部固定排序
        if (params['orderby']) {
            delete params['orderby'];
        }

        // 外部参数替换内部参数
        if (this.params) {
            let _keys = Object.keys(this.params);
            _keys.forEach(k => this.queryParams[k] = this.params[k]);
        }

        // 方法参数替换内部参数
        if (Object.keys(params).length) {
            let _keys = Object.keys(params);
            _keys.forEach(k => this.queryParams[k] = params[k]);
        }

        // 重置分页
        if (!isPage) {
            this.queryParams.page = 1;
        }

        this.ts.load(this.queryParams, this.url).pipe(
            map(res => {
                this.total = res.total;
                this.queryParams.page = res.page;
                return res.data;
            }),
            catchError(err => {
                this.msg.error(err);
                this.loaded();
                return of(err);
            })
        ).subscribe(rows => {
            this.dataSet = rows;
            this.loaded();
            this.cdr.detectChanges();
            this.onLoad.emit({
                total: this.total,
                data: rows
            });
        });
    }

    onSort(e) {
        if (this.sorts.hasOwnProperty(e.key) && null == e.value) {
            delete this.sorts[e.key];
        } else {
            this.sorts[e.key] = e.value.replace('end', '');
        }
        this.params['orderby'] = this.sorts;
        this.load(this._params);
        this.onSorted.emit(this.sorts);
    }

    onPageIndexChange(e) {
        this.queryParams.page = e;
        this.load(this._params, true);
        this.unChecked();
    }

    onPageSizeChange(e) {
        this.queryParams.limit = e;
        this.load(this._params);
        this.unChecked();
    }

    unChecked() {
        this.rowSelector = {
            checkedAll: false,
            isIndeterminate: false,
            mapOfCheckedId: {},
            selectedIds: [],
            selectedTotal: 0
        };
        this.onCheckedChange();
    }

    onCheckAll(value) {
        this.dataSet.forEach(item => (this.rowSelector.mapOfCheckedId[item.id] = value));
        this.onCheckedChange();
    }

    onCheckedChange() {
        this.rowSelector.checkedAll = this.dataSet.every(item => this.rowSelector.mapOfCheckedId[item.id]);
        this.rowSelector.isIndeterminate = this.dataSet.some(item => this.rowSelector.mapOfCheckedId[item.id]) && !this.rowSelector.checkedAll;
        this.rowSelector.selectedIds = this.dataSet.filter(item => this.rowSelector.mapOfCheckedId[item.id]).map(item => item.id);
        this.rowSelector.selectedTotal = this.rowSelector.selectedIds.length;
        this.onChecked.emit(this.rowSelector.selectedIds);
    }

    onDropRow(event: CdkDragDrop<string[]>): void {
        this.onDragSort.emit({
            dir: event.previousIndex > event.currentIndex ? 'up' : 'down',
            pre: this.dataSet[event.previousIndex]['id'],
            cur: this.dataSet[event.currentIndex]['id'],
        });
        moveItemInArray(this.dataSet, event.previousIndex, event.currentIndex);
    }

    loaded() {
        this.loading = false;
    }
}
