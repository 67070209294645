export class CastUtil {
    static castOptions(options: any[], targetValueField, targetLabelField, sourceValueField = 'id', sourceLabelField = 'text', childName = 'children', selectable = true) {
        options.forEach((option, i) => {
            if (option[sourceValueField] && option[sourceLabelField]) {
                options[i][targetValueField] = option[sourceValueField];
                options[i][targetLabelField] = option[sourceLabelField];
            }
            if ('children' != childName) {
                options[i]['children'] = option[childName];
            }
            if (options[i]['children'] && options[i]['children']) {
                options[i]['selectable'] = selectable;
                return this.castOptions(options[i]['children'], targetValueField, targetLabelField, sourceValueField, sourceLabelField);
            } else {
                options[i]['isLeaf'] = true;
            }
        });

        return options;
    }
    static castOptions2(options: any[], targetValueField, targetLabelField, sourceValueField = 'id', sourceLabelField = 'text') {
        options.forEach((option, i) => {
            if (option[sourceValueField] && option[sourceLabelField]) {
                options[i][targetValueField] = option[sourceValueField];
                options[i][targetLabelField] = option[sourceLabelField];
            }
        });

        return options;
    }
}
