import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ViewBase} from '../../../core/view/views';
import {ViewService} from '../../../core/view/view.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'my-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ViewComponent implements OnInit {

    @Input() goBackUrl = '';

    @Input() set views(_views: {
        title: string,
        items: ViewBase<any>[]
    }[]) {
        this._views = this.build(_views);
    };

    @Input() toolbar: boolean = true;

    _views: {
        title: string,
        items: ViewBase<any>[]
    }[] = [];

    constructor(
        private viewService: ViewService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit() {
    }

    private build(_views: {
        title: string,
        items: ViewBase<any>[]
    }[]) {
        _views.forEach(_view => {
            _view.items = this.viewService.build(_view.items);
        });
        return _views;
    }

    value(item) {
        return item.render ? item.render(item.value) : item.value;
    }

    goback() {
        this.router.navigate([this.goBackUrl], {relativeTo: this.route}).then();
    }
    isString(val) { return typeof val === 'string'; }
}
