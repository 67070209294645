import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormComponent, IFormCallBack} from '../form/form.component';
import {ControlBase} from '../../../core/form/controls/control-base';

@Component({
    selector: 'my-page-form',
    templateUrl: './page-form.component.html',
    styleUrls: ['./page-form.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PageFormComponent implements OnInit {

    @Input() method: 'post' | 'put' = 'post';
    @Input() url: string;
    @Input() goBackUrl: string = '';
    @Input() controls: ControlBase<any>[] = [];
    @Input() customSubmit: boolean = false;
    @Output() onSubmit: EventEmitter<any> = new EventEmitter;
    @Output() onSuccess: EventEmitter<IFormCallBack> = new EventEmitter;
    @Output() onFailure: EventEmitter<IFormCallBack> = new EventEmitter;

    @ViewChild('form', {static: false}) form: FormComponent;

    constructor(
    ) {
    }

    ngOnInit() {
    }

    submit(e) {
        this.onSubmit.emit(e);
    }

    success(e: IFormCallBack) {
        this.onSuccess.emit(e);
    }

    failure(e: IFormCallBack) {
        this.onFailure.emit(e);
    }
}
