import {ControlBase} from './control-base';
import {NzCascaderOption} from 'ng-zorro-antd';
import {HttpService} from '../../http/http.service';
import {LocatorService} from '../../base/services/locator.service';

export class ControlCascader extends ControlBase<string> {
    ControlType = 'cascader';

    http: HttpService = LocatorService.injector.get(HttpService);

    options: any[] = [];
    url: string = '';
    loading: boolean = false;
    labelProperty: string = 'text';
    valueProperty: string = 'id';
    lazy: boolean = false;
    lazyValue: string = '0';

    constructor(options: {} = {}) {
        super(options);
        this.value = (undefined != options['value']) ? options['value'] : [];
        this.url = options['url'] || this.url;
        this.loading = options['loading'] || this.loading;
        this.options = options['options'] || this.options;
        this.labelProperty = options['labelProperty'] || this.labelProperty;
        this.valueProperty = options['valueProperty'] || this.valueProperty;
        this.placeholder = options['placeholder'] || '请选择' + (options['label'] || '');
        this.lazy = (undefined != options['lazy']) ? !!options['lazy'] : this.lazy;
        this.lazyValue = (undefined != options['lazyValue']) ? options['lazyValue'] : this.lazyValue;
    }

    // 刷新控件
    refresh() {
        this.value = this.value;
    }

    loadData = (node: NzCascaderOption, index: number) => {
        return new Promise(resolve => {
            let q = this.valueProperty + '=' + ((undefined != node[this.valueProperty]) ? node[this.valueProperty] : this.lazyValue);
            let url = this.url + (this.url.indexOf('?') ? '&' : '?') + q;
            this.http.get(url).subscribe((res: any[]) => {
                node.children = res;
                // console.log(res);
                resolve();
            });
        });
    };
}
