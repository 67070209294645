import {ColumnBase} from './column-base';

export class ColumnSelector extends ColumnBase {
    ColumnType = 'selector';

    constructor(options: {} = {}) {
        super(options);
        this.width = '36px';
    }
}
