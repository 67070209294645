import {ColumnBase} from './column-base';

export class ColumnSelect extends ColumnBase {
    ColumnType = 'select';

    options: any = {};
    type: 'text' | 'icon' = 'text';

    constructor(options: {} = {}) {
        super(options);
        this.type = options['type'] || this.type;
        this.options = options['options'] || [];
        this.buildOptions();
    }

    buildOptions() {
        let _options = {};
        this.options.forEach(option => {
            _options[option['id']] = option;
        });
        this.options = _options;
    }
}
