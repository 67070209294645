import {ControlBase} from './control-base';

export class ControlRate extends ControlBase<string> {
    ControlType = 'rate';

    character: string = '<i class="la la-star"></i>';
    options: any[] = [];
    count: number = 5;

    constructor(options: {} = {}) {
        super(options);
        this.value = (undefined != options['value']) ? options['value'] : this.count;
        this.character = options['character'] || this.character;
        this.options = options['options'] || this.options;
        this.count = options['count'] || this.count;
    }
}
