import {ControlUpload} from './control-upload';

export class ControlAvatar extends ControlUpload {

    accepts: string = 'image/png,image/jpeg';

    constructor(options: {} = {}) {
        super(options);
        this.url = this.http.url(options['url']) || this.url;
        this.files = options['files'] || this.files;
        this.size = options['size'] || this.size;
        this.placeholder = options['placeholder'] || '请选择' + (options['label'] || '');
        this.multiple = (undefined != options['multiple']) ? !!options['multiple'] : false;
        this.value = (undefined != options['value']) ? options['value'] : (this.multiple ? [] : '');
        this.type = 'picture-card';
        this.showList = options['showUploadList'] || this.showList;
        this.limit = options['limit'] || this.limit;
    }
}
