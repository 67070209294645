import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ControlBase} from '../../../core/form/controls';
import {FormComponent} from '../form/form.component';
import {formatDate} from '@angular/common';

@Component({
    selector: 'my-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SearchBarComponent implements OnInit {

    @Input() controls: ControlBase<any>[] = [];
    @Output() onSearch = new EventEmitter();

    @Input() showButtons: boolean = true;

    @ViewChild('form', {static: true}) form: FormComponent;

    private _values = {};

    constructor() {
    }

    ngOnInit() {
        this.controls.forEach(control => {
            control.itemClass = 'm-b-0';
            control.span = (18 == control.span) ? 6 : control.span;
            control.offset = 0;
            this._values[control.name] = control.value;
        });
    }

    search() {
        this.form.submit();
    }

    reset() {
        this.form.reset();
        this.submit(this._values);
    }

    submit(e = {}) {
        let valueKeys = Object.keys(e);
        this.controls.forEach(control => {
            if (valueKeys.indexOf(control['name']) >= 0) {
                if (['date', 'month', 'year', 'time'].indexOf(control['type']) >= 0) {
                    e[control['name']] = formatDate(e[control['name']], control['format'], 'zh-Hans');
                } else if ('range' == control['type'] && e[control['name']].length == 2) {
                    e[control['name']] = [
                        formatDate(e[control['name']][0], control['format'], 'zh-Hans'),
                        formatDate(e[control['name']][1], control['format'], 'zh-Hans'),
                    ];
                }
                if (null === e[control['name']]) {
                    delete e[control['name']];
                }
                if (e[control['name']] && control['rule']) {
                    e[control['name']] = e[control['name']] + ':' + control['rule'];
                }
            } else {
                e[control['name']] = control['value'];
            }
        });
        this.onSearch.emit(e);
    }
}
