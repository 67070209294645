import {ControlBase} from './control-base';
import {formatDate} from '@angular/common';

export class ControlTime extends ControlBase<string> {
    ControlType = 'time';

    minuteStep: number = 1;
    secondStep: number = 1;
    format: string = 'hh:mm:ss';
    _value: Date = null;

    constructor(options: {} = {}) {
        super(options);
        this.value = (undefined != options['value']) ? options['value'] : null;
        this._value = this.castValue(this.value);
        this.minuteStep = options['minuteStep'] || this.minuteStep;
        this.secondStep = options['secondStep'] || this.secondStep;
        this.format = options['format'] || this.format;
        this.placeholder = options['placeholder'] || '请选择' + (options['label'] || '');
    }

    castValue(value) {
        if (value && 'string' == typeof value) {
            value = value.split(':');
            return new Date(2020, 1, 1, value[0] || 0, value[1] || 0, value[2] || 0, value[3] || 0);
        }
        return new Date();
    }

    onChange(e, control) {
        if (e) {
            control.value = formatDate(e, control.format, 'zh-CN');
        }
    }

    refresh() {
        this._value = this.castValue(this.value);
    }
}
