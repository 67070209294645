import * as auth from './core/auth/redurces/auth.redurces';
import * as base from './core/base/redurces/base.redurces';
import {createFeatureSelector} from '@ngrx/store';

export interface AppStates {
    authState: auth.State;
    baseState: base.State;
}

export const reducers = {
    auth: auth.reducer,
    base: base.reducer,
};

export const authState = createFeatureSelector<AppStates>('auth');
export const baseState = createFeatureSelector<AppStates>('base');
