// Angular
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {zip} from 'rxjs';

@Injectable()
export class ConfigService {

    private _menu: any = {};
    private _base: any = {};

    private _name = window.location.hostname.split('.').shift();

    constructor(
        private http: HttpClient
    ) {
    }

    load() {
        return zip(
            this.http.get(`assets/menu_${this._name}.json`),
            this.http.get(`assets/base_${this._name}.json`)
        ).subscribe(([menu, base]) => {
            this._menu = menu;
            this._base = base;
        }, error => {
            alert('非法访问');
        });
    }

    getMenu() {
        return this._menu;
    }

    getBase() {
        return this._base;
    }

    getName() {
        return this._name;
    }
}
