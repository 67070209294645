import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BaseComponent} from './views/theme/base/base.component';
import {AuthGuard} from './core/auth/auth.guard';
import {ErrorPageComponent} from './views/theme/error-page/error-page.component';


const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: '',
        component: BaseComponent,
        canActivate: [AuthGuard],
        // canActivateChild: [NgxPermissionsGuard],
        children: [
            {
                path: 'admin',
                loadChildren: () => import('./views/pages/admin/admin.module').then(m => m.AdminModule),
            },
            {
                path: 'merchant',
                loadChildren: () => import('./views/pages/merchant/merchant.module').then(m => m.MerchantModule),
            },
            {
                path: '',
                redirectTo: 'merchant',
                pathMatch: 'full'
            },
        ]
    },
    {
        path: '',
        redirectTo: 'error',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: 'error/403',
        pathMatch: 'full'
    },
    {
        path: 'error/:type',
        component: ErrorPageComponent
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
