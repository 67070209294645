import {ColumnBase} from './column-base';

interface columnAction {
    text: string;
    confirm?: boolean;
    click: any;
}

export class ColumnActions extends ColumnBase {
    ColumnType = 'actions';

    buttons: columnAction[] = [];

    constructor(options: {} = {}) {
        super(options);
        this.buttons = (options['buttons'] == undefined) ? this.buttons : options['buttons'];
        this.width = '60px';
        this.align = 'center';
    }
}
