import {Injectable} from '@angular/core';
import CryptoJS from 'crypto-js';
import {SecurityStorageService} from '../base/services/security-storage.service';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ConfigService} from '../base/services/config.service';

@Injectable()
export class AuthService {

    private tokenKey = CryptoJS.MD5('token').toString();
    private host = environment.host + environment.api;

    constructor(
        private ss: SecurityStorageService,
        private httpClient: HttpClient,
        private config: ConfigService
    ) {
    }

    login(username: string, password: string): Observable<any> {
        return this.httpClient.post(this.host + '/' + this.config.getName() + '/auth/login', {username, password}, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }),
        });
    }

    logout() {
        this.ss.cer();
    }

    token(secret = null) {
        return secret ? this.ss.set(this.tokenKey, secret) : (this.ss.get(this.tokenKey) ? this.ss.get(this.tokenKey) : '');
    }

    user() {
        return this.httpClient.get(this.host + '/' + this.config.getName() + '/user?include=roles', {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': this.token(),
            }),
        });
    }
}
