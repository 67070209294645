import {Action} from '@ngrx/store';

export enum AuthActionType {
    LOGIN = '[Auth] Login',
    LOGIN_SUCCESS = '[Auth] Login Success',
    LOGIN_FAILURE = '[Auth] Login Failure',
    LOGOUT = '[Auth] Logout',
    LOGOUT_SUCCESS = '[Auth] Logout Success',
    STATUS = '[Auth] Status',
}

export class Login implements Action {
    readonly type = AuthActionType.LOGIN;

    constructor(public payload: any) {
    }
}

export class LoginSuccess implements Action {
    readonly type = AuthActionType.LOGIN_SUCCESS;

    constructor(public payload: any) {
    }
}

export class LoginFailure implements Action {
    readonly type = AuthActionType.LOGIN_FAILURE;

    constructor(public payload: any) {
    }
}

export class Logout implements Action {
    readonly type = AuthActionType.LOGOUT;
}

export class LogoutSuccess implements Action {
    readonly type = AuthActionType.LOGOUT_SUCCESS;
}

export class Status implements Action {
    readonly type = AuthActionType.STATUS;

    constructor(public payload?: any) {
    }
}

export type All =
    Login |
    LoginSuccess |
    LoginFailure |
    Logout |
    LogoutSuccess |
    Status;
