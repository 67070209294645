import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from './auth.service';
import {Store} from '@ngrx/store';
import {AppStates, authState} from '../../app.states';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {Login, LoginSuccess} from './actions/auth.actions';
import {NgxPermissionsService} from 'ngx-permissions';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private auth: AuthService,
        private router: Router,
        private store: Store<AppStates>,
        private ps: NgxPermissionsService
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.store.select(authState).pipe(
            map((state: any) => state.isAuthenticated),
            switchMap(isAuthenticated => {
                if (isAuthenticated) {
                    return of(true);
                } else {
                    return this.auth.user().pipe(
                        map(user => {
                            if (!user['id']) {
                                this.router.navigateByUrl('/auth/login').then();
                                return false;
                            }

                            // load permissions
                            this.ps.loadPermissions(user['permissions']);

                            this.store.dispatch(new LoginSuccess(user));

                            return true;
                        }),
                        catchError(err => {
                            this.router.navigateByUrl('/auth/login').then();
                            return of(false);
                        })
                    );
                }
            })
        );
    }
}
