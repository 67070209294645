import {Component, EventEmitter, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {AmapPlaceSearchService, AmapPlaceSearchWrapper, ILabel} from 'ngx-amap';
import {NzCascaderOption, NzMessageService} from 'ng-zorro-antd';
import {HttpService} from '../../../core/http/http.service';
import {ConfigService} from '../../../core/base/services/config.service';


@Component({
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnInit {

    @Input() class: string = 'height-500';
    @Input() center: number[] = [104.065742, 30.657457];
    @Input() city: string = '';
    @Input() zoom: number = 13;

    @Input() set citiesLoadUrl(v) {
        if (v) {
            this._citiesLoadUrl = v;
        }
    };

    @Input() set cityLoadUrl(v) {
        if (v) {
            this._cityLoadUrl = v;
        }
    };

    // 已有标记配置
    @Input() markers: {
        position: number[],
        label?: ILabel,
        new?: boolean
    }[] = [];

    // 可以打标记的数量
    @Input() newMarkerCount: number = 0;
    @Input() newMarkerName: string = '地点';

    // 点击地图回调
    @Input() mapClickPosition: EventEmitter<any> = new EventEmitter<any>();

    _loading: boolean = true;

    plugin: Promise<AmapPlaceSearchWrapper>;

    _citiesLoadUrl = '';
    _cityLoadUrl = '';

    _position: {
        lng: number,
        lat: number
    } = {
        lng: null,
        lat: null
    };

    // 当前城市
    _city = null;
    _cityValue = [];

    // 地图对象
    _map = null;

    constructor(
        private amapPlaceSearch: AmapPlaceSearchService,
        private http: HttpService,
        private msg: NzMessageService,
        private config: ConfigService
    ) {
        this._citiesLoadUrl = this.config.getName() + '/common/cities?view=tree';
        this._cityLoadUrl = this.config.getName() + '/common/city';
    }

    ngOnInit() {
    }

    getPosition() {
        return this._position;
    }

    onMapReady(map) {
        this._map = map;
        this._loading = false;

        // 加载当前城市
        if (this._cityLoadUrl) {
            this.http.get(this._cityLoadUrl + '?id=' + this.city + '&filter=id;paths').subscribe(city => {
                if (city) {
                    this._cityValue = city['paths'] ? city['paths'].split(':') : city['paths'];
                }
            });
        }
    }

    onSearch(city: number[], keywords) {
        if (!city.length) {
            return this.msg.error('请选择城市');
        }
        if (!keywords) {
            return this.msg.error('请输入关键字');
        }
        if (this._cityLoadUrl) {
            this.http.get(this._cityLoadUrl + '?id=' + city.pop() + '&filter=id;code').subscribe(city => {
                this.plugin = this.amapPlaceSearch.of({
                    pageSize: 5,
                    pageIndex: 1,
                    city: city['code'],
                    map: this._map,
                    panel: 'panel',
                });
                this.plugin.then(placeSearch => {
                    placeSearch.on('listElementClick').subscribe(e => {
                        this._position.lng = e.data.location.lng;
                        this._position.lat = e.data.location.lat;

                        // 回调
                        this.mapClickPosition.emit(this._position);
                    });
                    placeSearch.on('markerClick').subscribe(e => {
                        this._position.lng = e.data.location.lng;
                        this._position.lat = e.data.location.lat;

                        // 回调
                        this.mapClickPosition.emit(this._position);
                    });
                });
                this.plugin.then(placeSearch => placeSearch.search(keywords)).then();
            });
        }
    }

    onMapClick(map) {
        // 当前位置
        this._position.lng = map.lnglat.lng;
        this._position.lat = map.lnglat.lat;

        // 在地图上显示新增标记 //

        // 获取新增地点数量
        let newTotal = 0;
        this.markers.forEach(marker => {
            newTotal += marker.new ? 1 : 0;
        });

        // 如果达到设置数量则删除最近一次增加的
        if (this.newMarkerCount == newTotal) {
            this.markers.splice(this.markers.length - 1);
        }

        // 增加标记
        this.markers.push({
            position: [this._position.lng, this._position.lat],
            label: {
                content: '新' + this.newMarkerName,
                offset: {
                    x: 20,
                    y: 0
                }
            },
            new: true
        });

        // 回调
        this.mapClickPosition.emit(this._position);
    }

    onMarkerClick(marker) {
        // 当前位置
        this._position.lng = marker.lnglat.lng;
        this._position.lat = marker.lnglat.lat;

        // 回调
        this.mapClickPosition.emit(this._position);
    }

    loadCityData = (node: NzCascaderOption, index: number) => {
        return new Promise(resolve => {
            let q = 'id=' + ((undefined != node['id']) ? node['id'] : '0');
            if (this._citiesLoadUrl) {
                let url = this._citiesLoadUrl + (this._citiesLoadUrl.indexOf('?') ? '&' : '?') + q;
                this.http.get(url).subscribe((res: any[]) => {
                    node.children = res;
                    resolve();
                });
            }
        });
    };
}
