import {All, BaseActionType} from '../actions/base.actions';

export interface State {
    pageInfo: {
        url: string,
        breadcrumb: any[],
        menu: any
    };
}

export const initialState: State = {
    pageInfo: {
        url: '',
        breadcrumb: [],
        menu: null
    },
};

export function reducer(state = initialState, action: All): State {
    switch (action.type) {
        case BaseActionType.PAGEINFO: {
            return {
                ...state,
                pageInfo: {
                    url: action.payload.url,
                    breadcrumb: action.payload.breadcrumb,
                    menu: action.payload.menu,
                },
            };
        }
        default:
            return state;
    }
}
