// Angular
import {Injectable} from '@angular/core';
import {ColumnBase} from './columns';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {HttpService} from '../http/http.service';
import {NgxPermissionsService} from 'ngx-permissions';

@Injectable()
export class TableService {

    constructor(
        private ps: NgxPermissionsService,
        private http: HttpService,
    ) {
    }

    buildColumns(columns: ColumnBase[]): ColumnBase[] {
        columns.forEach((column, index) => {
            if (column['buttons']) {
                column['buttons'].forEach((button, i) => {
                    if (undefined != button['permission']) {
                        this.ps.hasPermission(button['permission']).then(v => {
                            if (!v) {
                                columns[index]['buttons'].splice(i);
                            }
                        });
                    }
                });
            }
        });
        return columns;
    }

    load(params = {}, url: string = ''): Observable<{
        total: number,
        page: number,
        data: any[]
    }> {
        return this.http.get(url + '?' + this.parseQueryParams(params)).pipe(
            map((res: any) => {
                return {
                    total: undefined == res['total'] ? res.length : res['total'],
                    page: undefined == res['current_page'] ? 1 : res['current_page'],
                    data: undefined == res['data'] ? res : res['data'],
                };
            })
        );
    }

    parseQueryParams(params) {
        let p = {...params};

        // 解析过滤
        if (p['filter']) {
            p['filter'] = (undefined != p['filter'].join) ? p['filter'].join(';') : p['filter'];
        }

        // 解析搜索
        if (p['search']) {
            let search = [];
            let keys = Object.keys(p['search']);
            keys.forEach(k => {
                search.push(k + ':' + p['search'][k]);
            });
            p['search'] = search.join(';');
        }

        // 解析排序
        if (p['orderby']) {
            let sort = [];
            let keys = Object.keys(p['orderby']);
            keys.forEach(k => {
                sort.push(k + ':' + p['orderby'][k]);
            });
            p['orderby'] = sort.join(';');
        }

        let keys = Object.keys(p);
        let arr = [];
        keys.forEach(k => {
            arr.push(k + '=' + p[k]);
        });
        return arr.join('&');
    }
}
