import {ControlBase} from './control-base';
import {HttpService} from '../../http/http.service';
import {NzMessageService, UploadFile, UploadXHRArgs} from 'ng-zorro-antd';
import {HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';
import {LocatorService} from '../../base/services/locator.service';
import {isString} from 'util';

export class ControlUpload extends ControlBase<string> {
    ControlType = 'upload';
    allFile: any = [];
    http: HttpService = LocatorService.injector.get(HttpService);
    msg: NzMessageService = LocatorService.injector.get(NzMessageService);

    url: string = '';
    files: any[] = [];
    accepts: string = '';
    directory: boolean = false;
    multiple: boolean = false;
    showList: boolean = true;
    limit: number = 1;
    size: number = 0;
    headers: any[] = [];
    type: 'text' | 'picture' | 'picture-card';
    change: any = null;

    // local
    loading: boolean = false;
    previewVisible: boolean = false;
    previewImage: string | undefined = '';

    constructor(options: {} = {}) {
        super(options);
        this.url = this.http.url(options['url']) || this.url;
        this.files = options['files'] || this.files;
        this.accepts = options['accepts'] || this.accepts;
        this.directory = options['directory'] || this.directory;
        this.multiple = options['multiple'] || this.multiple;
        this.showList = options['showUploadList'] || this.showList;
        this.limit = options['limit'] || this.limit;
        this.size = options['size'] || this.size;
        this.headers = options['headers'] || this.headers;
        this.type = options['type'] || this.type;
        this.placeholder = options['placeholder'] || '请选择' + (options['label'] || '');
        this.value = (undefined != options['value']) ? options['value'] : (options['multiple'] ? [] : '');
        this.change = (undefined != options['change']) ? options['change'] : null;
        this.initFiles(options['value']);
        this.initShowList();
    }

    onUpload = (item: UploadXHRArgs) => {
        this.loading = true;
        return this.http.upload(item).subscribe(
            (event: HttpEvent<any>) => {
                if (event.type === HttpEventType.UploadProgress) {
                    if (event.total! > 0) {
                        (event as any).percent = (event.loaded / event.total!) * 100;
                    }
                    item.onProgress!(event, item.file!);
                } else if (event instanceof HttpResponse) {
                    if (!event['body'] || !event['body']['success']) {
                        this.msg.error(event['body']['message']);
                        item.onError(event['body']['message'], item.file);
                    } else {
                        // 更新控件值
                        item.onSuccess(event['body'], item.file!, event);
                    }
                }
                this.loading = false;
            },
            err => {
                this.msg.error(err);
                item.onError!(err, item.file!);
                this.loading = false;
            },
        );
    };

    onChange(info: any) {
        switch (info.file.status) {
            case 'uploading':
                break;
            case 'removed':
                this.removeFile(info.file);
                break;
            case 'done':
                this.pushFile(info.file);
                break;
            case 'error':
                break;
            case 'success':
                break;
        }
    };

    initFiles(value: any) {
        if (!value && this.multiple) {
            this.allFile = [];
        } else if (!value && !this.multiple) {
            this.allFile = '';
        } else {
            this.allFile = value;
        }
        let _files = [];
        if (undefined != value) {
            if (undefined != value.forEach) {
                value.forEach(v => {
                    let _name = v.split('\\').pop().split('/').pop();
                    _files.push({
                        uid: _name,
                        name: _name,
                        status: 'done',
                        url: this.http.url(v, false),
                    });
                });
            } else {
                if (isString(value)) {
                    let _name = value.split('\\').pop().split('/').pop();
                    _files.push({
                        uid: _name,
                        name: _name,
                        status: 'done',
                        url: this.http.url(value, false),
                    });
                }
            }
        }
        this.files = _files;
    }

    pushFile(file) {
        if (file) {
            this.files.push({
                uid: file.uid,
                name: file.name,
                status: 'done',
                response: 'success',
                url: file.response.message,
            });
            if (this.multiple) {
                // if (!this.value || 'object' !== typeof this.value) {
                //     this.value = [];
                // }
                this.allFile.push(file.response.message);
            } else {
                this.allFile = file.response.message;
            }
            if ('function' == typeof this.change) {
                this.change(this.files);
            }
        }
        this.value = this.allFile;
    }

    removeFile(file) {
        if (this.files.length > 0) {
            this.files.splice(this.files.indexOf(file.uid));
        }
        if (this.multiple) {
            this.value.splice(this.value.indexOf(file.url), 1);
        }
        if ('function' == typeof this.change) {
            this.change(this.files);
        }
    }

    initShowList() {
        return 'text' == this.type ? this.showList : {
            showPreviewIcon: true,
            showRemoveIcon: true,
            hidePreviewIconInNonImage: true
        };
    }

    handlePreview = (file: UploadFile) => {
        this.previewImage = file.url || file.thumbUrl;
        this.previewVisible = true;
    };

    // 刷新控件
    refresh() {
        if (this.value) {
            this.initFiles(this.value);
            this.initShowList();
        }
    }
}
