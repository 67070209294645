import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppStates} from './app.states';
import {Navigated} from './core/base/actions/base.actions';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
    `,
})
export class AppComponent implements OnInit, OnDestroy {

    private events$ = null;

    constructor(
        private router: Router,
        private store: Store<AppStates>,
    ) {
    }

    ngOnInit(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            distinctUntilChanged(),
            map((ne: NavigationEnd) => ne.urlAfterRedirects),
            map(url => this.store.dispatch(new Navigated(url))),
        ).subscribe();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
    }

    ngOnDestroy(): void {
        this.events$.unsubscribe();
    }
}
