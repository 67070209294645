import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

// NGRX
import {StoreModule} from '@ngrx/store';

// ng-zorro import
import {NgZorroAntdModule, NZ_CONFIG, NZ_I18N, NzConfig, zh_CN} from 'ng-zorro-antd';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import zh from '@angular/common/locales/zh';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {ThemeModule} from './views/theme/theme.module';
import {CoreModule} from './core/core.module';
import {PartialsModule} from './views/partials/partials.module';
import {reducers} from './app.states';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './core/auth/effects/auth.effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment.prod';
import {NgxPermissionsModule} from 'ngx-permissions';

// custom
import {LocatorService} from './core/base/services/locator.service';
import {BaseEffects} from './core/base/effects/base.effects';
import {ConfigService} from './core/base/services/config.service';

// ng-zorro set lang
registerLocaleData(zh);

// ng-zorro default config
const ngZorroConfig: NzConfig = {
    message: {nzTop: 120},
    notification: {nzTop: 240}
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        StoreModule.forRoot(reducers, {}),
        EffectsModule.forRoot([AuthEffects, BaseEffects]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production
        }),
        NgxPermissionsModule.forRoot(),
        NgZorroAntdModule,
        CoreModule,
        PartialsModule,
        ThemeModule,
    ],
    providers: [
        {
            provide: NZ_I18N,
            useValue: zh_CN
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: NZ_CONFIG,
            useValue: ngZorroConfig
        },
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigService) => function() {
                return configService.load();
            },
            deps: [ConfigService],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private injector: Injector) {
        LocatorService.injector = injector;
    }
}
