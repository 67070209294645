import {CastUtil} from '../../utils/cast.util';

export class ControlBase<T> {
    value: T | any;
    span: number;
    offset: number;
    ControlType: string;
    remote: boolean;
    name: string;
    label: string;
    placeholder: string;
    error: string;
    disabled: boolean;
    clear: boolean;
    required: boolean;
    rule: string;
    class: string;
    itemClass: string;
    flex: boolean;
    exclude: boolean;

    constructor(options: {
        value?: T | any,
        span?: number,
        offset?: number,
        ControlType?: string,
        remote?: boolean;
        name?: string,
        label?: string,
        placeholder?: string,
        error?: string,
        disabled?: boolean,
        clear?: boolean,
        required?: boolean,
        rule?: string,
        class?: string,
        itemClass?: string,
        flex?: boolean,
        exclude?: boolean,
    } = {}) {
        this.value = options.value || null;
        this.span = (options.span == undefined) ? 18 : options.span;
        this.offset = (options.offset == undefined) ? ((options.span == undefined) ? 3 : 0) : options.offset;
        this.ControlType = options.ControlType || '';
        this.remote = options.remote || false;
        this.name = options.name || '';
        this.label = options.label || '';
        this.placeholder = options.placeholder || '';
        this.disabled = (options.disabled == undefined) ? false : options.disabled;
        this.clear = (options.clear == undefined) ? false : options.clear;
        this.required = (options.required == undefined) ? false : options.required;
        this.error = this.required ? (options.error ? options.error : this.label + '不能为空') : '';
        this.rule = (options.rule == undefined) ? '' : options.rule;
        this.class = (options.class == undefined) ? '' : options.class;
        this.itemClass = (options.itemClass == undefined) ? '' : options.itemClass;
        this.flex = (options.flex == undefined) ? true : options.flex;
        this.exclude = (options.exclude == undefined) ? false : !!options.exclude;
    }

    // 选项字段转换
    castOptions(options: any[], targetValueField, targetLabelField, sourceValueField = 'id', sourceLabelField = 'text') {
        return CastUtil.castOptions(options, targetValueField, targetLabelField, sourceValueField, sourceLabelField);
    }
}
