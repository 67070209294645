// Angular
import {Injectable} from '@angular/core';
import {ControlBase} from './controls';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpService} from '../http/http.service';

@Injectable()
export class FormService {

    controls: ControlBase<any>[] = [];
    formGroup: FormGroup;

    // 初始值
    private _values = {};

    constructor(
        private fb: FormBuilder,
        private http: HttpService
    ) {
    }

    /**
     * build form
     * @param controls
     */
    build(controls: ControlBase<any>[] = []) {
        if (this.formGroup) {
            Object.keys(this.controls).forEach(control => {
                if (control['name']) {
                    this.formGroup.removeControl(control['name']);
                }
            });
        }

        this.formGroup = this.buildGroup(controls);
        this.controls = this.buildControls(controls);
    }

    /**
     * reset controls value
     */
    reset() {
        this.controls.forEach(control => {
            if (this.formGroup.controls[control.name]) {
                if (undefined != this._values[control.name]) {
                    this.formGroup.controls[control.name].setValue(this._values[control.name]);
                } else {
                    this.formGroup.controls[control.name].setValue(control.value);
                }
                this.formGroup.controls[control.name].markAsPristine();
                this.formGroup.controls[control.name].markAsUntouched();
                this.formGroup.controls[control.name].updateValueAndValidity();
            }
        });
    }

    /**
     * get form values
     */
    values() {
        let values = {};

        this.controls.forEach(control => {
            if (!control['exclude']) {
                values[control.name] = control.value;
                if (this.formGroup.controls[control.name]) {
                    values[control.name] = (undefined !== control['getValue'] && "function" === control['getValue']) ? control['getValue']() : this.formGroup.controls[control.name].value;
                }
            }
        });

        return values;
    }

    /**
     * refresh controls value
     */
    refresh() {
        this.controls.forEach(control => {
            undefined != control['refresh'] && control['refresh']();
        });
    }

    /**
     * build form group
     *
     * @param controls
     */
    private buildGroup(controls: ControlBase<any>[]): FormGroup {
        let group: any = {};

        controls.forEach(control => {
            group[control.name] = new FormControl(control.value || null, control.required ? Validators.required : null);
            // 设置初始值
            if (undefined == this._values[control.name]) {
                this._values[control.name] = control.value;
            }
        });

        return this.fb.group(group);
    }

    /**
     * process custom control init
     *
     * @param controls
     */
    private buildControls(controls: ControlBase<any>[]): ControlBase<any>[] {
        controls.forEach(control => {
            // 加载远程数据
            this.load(control);
        });

        return controls;
    }

    /**
     * load remote data
     *
     * @param control
     */
    private load(control) {
        if (control['remote']) {
            control['load'] = (_url: string = null) => {
                let method = (undefined == control['method'] || !control['method']) ? 'get' : control['method'];
                let url = _url ? _url : control['url'];
                this.http[method](url).subscribe((options: any[]) => {
                    control['options'] = options;
                    if (undefined != control['refresh']) {
                        control['refresh']();
                    }
                    // 默认选中？
                    if (control['selected'] && options.length) {
                        if ('first' == control['selected']) {
                            if (undefined != control['group'] && control['group']) {
                                for (let i = 0; i < options.length; i++) {
                                    if (options[i][control['groupChildProperty']].length) {
                                        control['value'] = options[i][control['groupChildProperty']][0][control['valueProperty']];
                                        break;
                                    }
                                }
                            } else {
                                control['value'] = options[0][control['valueProperty']];
                            }
                        } else if ('last' == control['selected']) {
                            if (undefined != control['group'] && control['group']) {
                                for (let i = (options.length - 1); i > -1; i--) {
                                    if (options[i][control['groupChildProperty']].length) {
                                        control['value'] = options[i][control['groupChildProperty']][options[i][control['groupChildProperty']].length - 1][control['valueProperty']];
                                        break;
                                    }
                                }
                            } else {
                                control['value'] = options[options.length - 1][control['valueProperty']];
                            }
                        }
                    }
                    // 没有数据
                    if (!options.length) {
                        control['value'] = ('default' == control['mode']) ? null : [];
                        control['change'](control['value']);
                    }
                });
            };
            if (!control['lazy']) {
                control['load']();
            }
        }
    }
}
