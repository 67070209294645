import {ControlBase} from './control-base';

export class ControlHide extends ControlBase<string> {
    ControlType = 'hide';

    constructor(options: {} = {}) {
        super(options);
        this.value = (undefined != options['value']) ? options['value'] : '';
    }
}
