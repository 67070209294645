export class ColumnBase {
    ColumnType: string;
    name: string;
    title: string;
    titleClass: string;
    sort: boolean;
    align: 'left' | 'center' | 'right';
    width: string;
    valueClass: string;
    click: (row: any) => any;
    render: (row: any) => any;
    indent: any;

    constructor(options: {
        ColumnType?: string,
        name?: string,
        title?: string,
        titleClass?: string,
        sort?: boolean,
        align?: 'left' | 'center' | 'right',
        width?: string,
        valueClass?: string,
        click?: (row: any) => any,
        render?: (row: any) => any,
        indent?: any,
    } = {}) {
        this.ColumnType = options.ColumnType || 'text';
        this.name = options.name || '';
        this.title = (options.title == undefined) ? '' : options.title;
        this.titleClass = (options.titleClass == undefined) ? '' : options.titleClass;
        this.sort = (options.sort == undefined) ? false : options.sort;
        this.align = (options.align == undefined) ? 'left' : options.align;
        this.width = (options.width == undefined) ? '' : options.width;
        this.valueClass = (options.valueClass == undefined) ? '' : options.valueClass;
        this.click = (options.click == undefined) ? null : options.click;
        this.render = (options.render == undefined) ? null : options.render;
        this.indent = (options.indent == undefined) ? null : options.indent;
    }

    parseValue(row) {
        if (this.render) {
            return this.render(row);
        }
        if (this.name.split('.').length > 1) {
            return this.resolve(this.name, row);
        } else {
            return row[this.name];
        }
    }

    resolve(path, obj, separator = '.') {
        const properties = Array.isArray(path) ? path : path.split(separator);
        return properties.reduce((prev, curr) => prev && prev[curr], obj);
    }

    getIndent(row): number {
        return (parseInt(('function' == typeof this.indent) ? this.indent(row) : this.indent) - 1) * 20;
    }
}
