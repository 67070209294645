import {ControlBase} from './control-base';

export class ControlSelect extends ControlBase<string> {
    ControlType = 'select';

    mode: 'multiple' | 'tags' | 'default' = 'default';
    group: boolean = false;
    url: string = '';
    loading: boolean = false;
    options: any[] = [];
    labelProperty: string = 'text';
    valueProperty: string = 'id';
    groupProperty: string = 'group';
    groupLabelProperty: string = 'text';
    groupChildProperty: string = 'children';
    lazy: boolean = false;
    maxMultipleCount: number = 99;
    search: boolean = false;
    selected: null | 'first' | 'last' = null;
    change: any = (e: any) => {
    };

    constructor(options: {} = {}) {
        super(options);
        this.mode = options['mode'] || this.mode;
        this.group = (undefined != options['group']) ? !!options['group'] : this.group;
        this.url = options['url'] || this.url;
        this.loading = options['loading'] || this.loading;
        this.value = (undefined != options['value']) ? options['value'] : ('default' == this.mode ? null : []);
        this.options = options['options'] || this.options;
        this.labelProperty = options['labelProperty'] || this.labelProperty;
        this.valueProperty = options['valueProperty'] || this.valueProperty;
        this.groupProperty = options['groupProperty'] || this.groupProperty;
        this.groupLabelProperty = options['groupLabelProperty'] || this.groupLabelProperty;
        this.groupChildProperty = options['groupChildProperty'] || this.groupChildProperty;
        this.maxMultipleCount = options['maxMultipleCount'] || this.maxMultipleCount;
        this.placeholder = options['placeholder'] || '请选择' + (options['label'] || '');
        this.selected = options['selected'] || this.selected;
        this.lazy = (undefined != options['lazy']) ? !!options['lazy'] : this.lazy;
        this.change = options['change'] || this.change;
        this.search = options['search'] || this.search;

    }
}
