import {Injectable} from '@angular/core';
import {AuthService} from '../auth.service';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthActionType, Login, LoginFailure, LoginSuccess, Logout, LogoutSuccess, Status} from '../actions/auth.actions';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {Action} from '@ngrx/store';
import {MyResponse} from '../../http/MyResponse.interface';
import {NgxPermissionsService} from 'ngx-permissions';

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
        private ps: NgxPermissionsService
    ) {
    }

    @Effect()
    Login$: Observable<Action> = this.actions$.pipe(
        ofType<Login>(AuthActionType.LOGIN),
        map((action: Login) => action.payload),
        switchMap(payload => {
            // request login
            return this.authService.login(payload.username, payload.password).pipe(
                map((res: MyResponse) => {
                    if (true == res.success) {
                        // set token
                        this.authService.token(res.message.token_type + ' ' + res.message.access_token);
                        return true;
                    } else {
                        return new LoginFailure(res.message);
                    }
                }),
                catchError(error => {
                    return of(new LoginFailure(error.error.message));
                })
            );
        }),
        switchMap((result: any) => {
            // load user
            if (true === result) {
                return this.authService.user().pipe(
                    map(user => {
                        // load permissions
                        if (user['permissions']) {
                            this.ps.loadPermissions(user['permissions']);
                        }
                        // goto home
                        this.router.navigateByUrl('/').then();
                        return new LoginSuccess(user);
                    }),
                    catchError(error => {
                        return of(new LoginFailure(error.error));
                    })
                );
            } else {
                return of(result);
            }
        }),
        catchError(error => {
            return of(new LoginFailure(error.error));
        })
    );

    @Effect()
    Logout$: Observable<Action> = this.actions$.pipe(
        ofType<Logout>(AuthActionType.LOGOUT),
        map((action: Logout) => {
            this.authService.logout();
            this.router.navigateByUrl('/auth/login').then();
            return new LogoutSuccess();
        })
    );
}
