import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {HttpService} from '../../../core/http/http.service';
import {FormService} from '../../../core/form/form.service';
import {ControlBase} from '../../../core/form/controls';
import {DateUtil} from '../../../core/utils/date.util';

export interface IFormCallBack {
    values: any,
    message: any,
    code: any,
}

@Component({
    selector: 'my-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [FormService]
})
export class FormComponent implements OnInit {

    @Input() method: 'post' | 'put' = 'post';
    @Input() url: string = '';
    @Input() goBackUrl: string = '';
    @Input() okText: string = '确定';
    @Input() cancelText: string = '取消';

    @Input() set controls(_controls: ControlBase<any>[]) {
        this.build(_controls);
    };

    @Input() loading = false;

    @Input() customSubmit: boolean = false;
    @Output() onSubmit: EventEmitter<any> = new EventEmitter;
    @Output() onSuccess: EventEmitter<IFormCallBack> = new EventEmitter;
    @Output() onFailure: EventEmitter<IFormCallBack> = new EventEmitter;

    @ViewChild('form', {static: false}) form: ElementRef;

    // 生成的控件组
    _controls: ControlBase<any>[] = [];

    // 表单对象
    _formGroup: FormGroup;

    constructor(
        private fb: FormBuilder,
        private httpClient: HttpClient,
        private http: HttpService,
        private formService: FormService
    ) {
    }

    ngOnInit() {
    }

    private build(_controls: ControlBase<any>[]) {
        this.formService.build(_controls);
        this._controls = this.formService.controls;
        this._formGroup = this.formService.formGroup;
    }

    reset() {
        this.formService.reset();
    }

    // 提交
    submit() {
        // 验证
        if (this._formGroup.invalid) {
            for (const k in this._formGroup.controls) {
                this._formGroup.controls[k].markAsDirty();
                this._formGroup.controls[k].updateValueAndValidity();
            }
            return;
        }

        let values = this.formService.values();

        Object.keys(values).forEach((k, index) => {
            if (values[k] instanceof Date) {
                values[k] = DateUtil.format(values[k], this._controls[index]['format']);
            }
        });

        // 是否自定义提交
        if (this.customSubmit) {
            return this.onSubmit.emit(values);
        }

        if (this.loading) {
            return;
        }

        this.loading = true;

        this.http[this.method](this.url, values).subscribe(res => {
            if (res.success) {
                this.onSuccess.emit({
                    values: values,
                    message: res.message,
                    code: res.code,
                });
            } else {
                this.onFailure.emit({
                    values: values,
                    message: res.message,
                    code: res.code,
                });
            }
            this.loading = false;
        }, error => {
            this.onFailure.emit({
                values: values,
                message: error,
                code: null,
            });
            this.loading = false;
        });
    }

}
