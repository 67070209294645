import {Action} from '@ngrx/store';

export enum BaseActionType {
    NAVIGATED = '[Base] Navigated',
    PAGEINFO = '[Base] PageInfo',
}

export class Navigated implements Action {
    readonly type = BaseActionType.NAVIGATED;

    constructor(public payload: any) {
    }
}

export class PageInfo implements Action {
    readonly type = BaseActionType.PAGEINFO;

    constructor(public payload: any) {
    }
}

export type All =
    Navigated |
    PageInfo
