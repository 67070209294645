import {ControlBase} from './control-base';

export class ControlCheckbox extends ControlBase<string> {
    ControlType = 'checkbox';

    options: any[] = [];
    labelProperty: string = 'text';
    valueProperty: string = 'id';

    constructor(options: {} = {}) {
        super(options);
        this.labelProperty = options['labelProperty'] || this.labelProperty;
        this.valueProperty = options['valueProperty'] || this.valueProperty;
        this.value = (undefined != options['value']) ? options['value'] : [];
        this.options = this.castValue(options['options'], this.value);
    }

    // 复选框初始值处理
    onChange(e: any[], control) {
        if (e){
            control.value = [];
            e.forEach(r => {
                if (r.checked) {
                    control.value.push(r[this.valueProperty]);
                }
            });
        }
    }

    // 复选框转换
    castValue(options: any[], value) {
        if (options) {
            options = this.castOptions(options, 'value', 'label', this.valueProperty, this.labelProperty);
            options.forEach((option, i) => {
                options[i]['checked'] = (value.indexOf(option[this.valueProperty]) >= 0);
            });
            return options;
        }

        return [];
    }
}
