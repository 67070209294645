import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {SearchBarComponent} from '../search-bar/search-bar.component';
import {TableComponent} from '../table/table.component';
import {ControlBase} from '../../../core/form/controls';

@Component({
    selector: 'my-page-table',
    templateUrl: './page-table.component.html',
    styleUrls: ['./page-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PageTableComponent implements OnInit {

    @Input() title: string = '';

    // 搜索
    @Input() search: ControlBase<any>[] = [];

    // 表格
    @Input() table: {
        columns?: any[],
        params?: any,
        auto?: boolean,
    } = {
        columns: [],
        params: {},
        auto: true,
    };

    // 工具栏
    @Input() tools: {
        actions?: any[],
        menus?: any[]
    } = {
        actions: [],
        menus: []
    };

    // 表格加载完成事件
    @Output() onTableLoaded = new EventEmitter();

    // 表格行拖拽事件
    @Output() onDragSorted = new EventEmitter();

    // 搜索表单
    @ViewChild('search_view', {static: false}) searchCom: SearchBarComponent;

    // 表格配置
    @ViewChild('table_view', {static: false}) tableCom: TableComponent;

    constructor() {
    }

    ngOnInit() {
    }

    reload() {
        // 选中数清零
        this.onTableChecked([]);
        // 重新加载
        if (this.searchCom) {
            this.searchCom.submit();
        } else {
            this.tableCom.load();
        }
    }

    doSearch() {
        this.searchCom.form.submit();
    }

    onSearch(e) {
        // 原始搜索项
        let search = e;
        if (!this.table['params']) {
            this.table['params'] = {};
        }
        if (this.table['params']['search']) {
            let keys = Object.keys(this.table['params']['search']);
            keys.forEach(k => {
                search[k] = this.table['params']['search'][k];
            });
        }
        // 表单搜索项目
        let keys = Object.keys(e);
        keys.forEach(k => {
            if (k.length && !search[k]) {
                search[k] = e[k];
            }
        });
        Object.keys(search).forEach(k => {
            if (!k) {
                delete search[k];
            }
        });
        // 加载数据
        this.tableCom.load({
            search: search,
            filter: this.table['params']['filter'] ? this.table['params']['filter'] : ''
        });
    }

    onTableChecked(e) {
        this.table['checked'] = e;
        // 按钮组中需要显示选中数量的按钮
        if (this.tools['actions']) {
            this.tools['actions'].forEach((action, index) => {
                if (action.hasOwnProperty('checked')) {
                    if (e.length) {
                        this.tools['actions'][index].text_checked = this.tools['actions'][index].text + ' ' + e.length + ' 项';
                        this.tools['actions'][index].checked = false;
                        this.tools['actions'][index].data = e;
                    } else {
                        this.tools['actions'][index].text_checked = this.tools['actions'][index].text;
                        this.tools['actions'][index].checked = true;
                    }
                }
            });
        }
    }

    onTableLoad(e) {
        this.onTableLoaded.emit(e);
    }

    onDragSort(e) {
        this.onDragSorted.emit(e);
    }
}
