import {ControlBase} from './control-base';

export class ControlNumber extends ControlBase<string> {
    ControlType = 'number';

    min: number = 0;
    max: number = 999999999;
    step: number = 1;
    precision: number = 1;

    constructor(options: {} = {}) {
        super(options);
        this.value = (undefined != options['value']) ? options['value'] : null;
        this.min = options['min'] || this.min;
        this.max = options['max'] || this.max;
        this.step = options['step'] || this.step;
        this.precision = options['precision'] || this.precision;
        this.placeholder = options['placeholder'] || '请输入' + (options['label'] || '');
    }
}
