import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {SearchBarComponent} from '../search-bar/search-bar.component';
import {TableComponent} from '../table/table.component';
import {ControlBase} from '../../../core/form/controls';
import {FormComponent} from '../form/form.component';
import {MyResponse} from '../../../core/http/MyResponse.interface';
import {HttpService} from '../../../core/http/http.service';
import {NzMessageService} from 'ng-zorro-antd';
import {ColumnActions} from '../../../core/table/columns';
import {ViewBase} from '../../../core/view/views';
import {ObjectUtil} from '../../../core/utils/object.util';
import {NgxPermissionsService} from 'ngx-permissions';

interface IPageTableCrudConfig {
    title: string,
    read: {
        url: string,
        width: number,
        title: string,
        permission: '',
        views: ViewBase<any>[]
    },
    create: {
        url: string,
        width: number,
        title: string,
        permission: string,
        controls: ControlBase<any>[]
    },
    update: {
        url: string,
        width: number,
        title: string,
        permission: string,
        controls: ControlBase<any>[]
    },
    delete: {
        url: string,
        permission: string,
    },
    sort: {
        url: string,
    }
}

@Component({
    selector: 'my-page-table-crud',
    templateUrl: './page-table-crud.component.html',
    styleUrls: ['./page-table-crud.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PageTableCrudComponent implements OnInit {

    // 页面
    @Input() set config(_config: IPageTableCrudConfig) {
        this.buildConfig(_config);
        this.buildTools();
    };

    // 搜索
    @Input() search: ControlBase<any>[] = [];

    // 值转化
    @Input() transforms: {
        filed: string,
        value: any
    }[] = [];

    // 表格
    @Input() set table(_table: {
        url: string,
        columns: any[],
        params: any,
        checked: any[],
        autoLoad: boolean,
        dragSort: boolean,
    }) {
        this.buildTable(_table);
    };

    // 配置
    _config: IPageTableCrudConfig = {
        title: '',
        read: {
            url: '',
            width: 520,
            title: '',
            permission: '',
            views: []
        },
        create: {
            url: '',
            width: 520,
            title: '',
            permission: '',
            controls: []
        },
        update: {
            url: '',
            width: 520,
            title: '',
            permission: '',
            controls: []
        },
        delete: {
            url: '',
            permission: '',
        },
        sort: {
            url: '',
        }
    };

    // 工具栏
    tools = {
        actions: [],
        menus: []
    };

    // 表格
    _table = {
        url: '',
        columns: [],
        params: {},
        checked: [],
        autoLoad: true,
        dragSort: false,
    };

    // 表单
    form: {
        url: string,
        queryStr?: string,
        width: number,
        title: string,
        controls: ControlBase<any>[],
        method?: string,
        show?: boolean,
        loading?: boolean,
        loadTip?: string,
        map?: any,
    } = {
        url: '',
        queryStr: '',
        width: 520,
        title: '',
        controls: [],
        method: 'post',
        show: false,
        loading: true,
        loadTip: '',
        map: null,
    };

    // 视图
    view: {
        url: string,
        queryStr?: string,
        width: number,
        title: string,
        views: ViewBase<any>[],
        show?: boolean,
        loading?: boolean,
        map?: any,
    } = {
        url: '',
        queryStr: '',
        width: 520,
        title: '',
        views: [],
        show: false,
        loading: false,
        map: null,
    };

    // 搜索表单
    @ViewChild('search_view', {static: false}) searchCom: SearchBarComponent;

    // 表格配置
    @ViewChild('table_view', {static: false}) tableCom: TableComponent;

    // 表格配置
    @ViewChild('form_view', {static: false}) formCom: FormComponent;

    constructor(
        private ps: NgxPermissionsService,
        private msg: NzMessageService,
        private http: HttpService
    ) {
    }

    ngOnInit() {
    }

    buildConfig(_config: IPageTableCrudConfig) {
        this._config.title = (undefined == _config.title) ? '列表' : _config.title;
        this._config.read = (undefined == _config.read) ? {
            url: '',
            views: [],
            title: '',
            permission: '',
            width: 520,
        } : _config.read;
        this._config.create = (undefined == _config.create) ? {
            url: '',
            title: '',
            width: 520,
            permission: '',
            controls: []
        } : _config.create;
        this._config.update = (undefined == _config.update) ? {
            url: '',
            title: '',
            width: 520,
            permission: '',
            controls: []
        } : _config.update;
        this._config.delete = (undefined == _config.delete) ? {
            url: '',
            permission: '',
        } : _config.delete;
        this._config.sort = (undefined == _config.sort) ? {
            url: '',
        } : _config.sort;
    }

    buildTools() {
        // 生成工具栏
        if (this._config.create.url) {
            this.tools.actions.push({
                type: 'primary',
                text: '增加',
                icon: 'la la-plus',
                permission: this._config.create.permission,
                callback: () => {
                    let id = null;
                    if (this._table.checked && this._table.checked.length) {
                        id = this._table.checked[0];
                    }
                    this.buildForm('create', id);
                    this.showFormModal();
                }
            });
        }
        if (this._config.delete.url) {
            this.tools.actions.push({
                type: 'danger',
                text: '删除',
                icon: 'la la-times',
                permission: this._config.delete.permission,
                checked: true,
                confirm: true,
                callback: (ids) => {
                    this.delete(ids.join(','));
                }
            });
        }
    }

    buildTable(table: {
        url: string,
        columns: any[],
        params: any,
        checked: any[],
        autoLoad: boolean,
        dragSort: boolean,
    }) {
        // 链接替换为事件
        table.columns.forEach(column => {
            if ('ColumnLink' == column.constructor.name && 'detail' == column['action']) {
                column.valueClass = 'bold';
                column.click = (row) => {
                    this.rowView(row);
                };
            }
        });
        // 增加操作按钮
        let buttons = [];
        if (this._config.read.url) {
            buttons.push({
                text: '查看',
                permission: this._config.read.permission,
                click: (row) => {
                    this.rowView(row);
                }
            });
        }
        if (this._config.update.url) {
            buttons.push({
                text: '修改',
                permission: this._config.update.permission,
                click: (row) => {
                    this.rowUpdate(row);
                }
            });
        }
        if (this._config.delete.url) {
            buttons.push({
                text: '删除',
                permission: this._config.delete.permission,
                confirm: true,
                click: (row) => {
                    this.rowDelete(row);
                }
            });
        }
        if (buttons.length) {
            table.columns.push(
                new ColumnActions({
                    title: '操作',
                    buttons: buttons
                })
            );
        }
        this._table = table;
    }

    rowView(row) {
        this.buildView(row);
        this.view.show = true;
    }

    rowUpdate(row) {
        this.buildForm('update', row['id']);
        this.showFormModal();
    }

    rowDelete(row) {
        this.delete(row['id']);
    }

    buildForm(action: 'create' | 'update', id: string = null) {
        this.form = this._config[action];
        this.form.method = ('update' == action) ? 'put' : 'post';
        if (id) {
            this.form.loading = true;
            this.form.loadTip = '正在加载';
            this.http.get(this.form.url + '?id=' + id + (this.form.queryStr ? '&' + this.form.queryStr : '')).subscribe(res => {
                if (res['id']) {
                    if (this.form.map) {
                        res = this.form.map(res);
                    }
                    let keys = Object.keys(res);
                    this.form.controls.forEach(control => {
                        if (keys.indexOf(control.name) >= 0 && res[control.name]) {
                            control.value = res[control.name];
                            if (undefined !== control['refresh']) {
                                control['refresh']();
                            }
                        }
                    });
                }
                this.form.loading = false;
            });
        } else {
            this.form.loading = false;
        }
    }

    buildView(row: any) {
        this.view = this._config.read;
        if (!this.view.loading) {
            this.view.loading = true;
            this.http.get(this.view.url + '?id=' + row['id'] + (this.view.queryStr ? '&' + this.view.queryStr : '')).subscribe(res => {
                if (this.view.map) {
                    res = this.view.map(res);
                }
                this.view.views.forEach(view => {
                    view.value = ObjectUtil.findByPath(res, view.name);
                });
                this.view.loading = false;
            });
        }
    }

    reload() {
        // 选中数清零
        this.tableCom.onCheckAll(false);
        this.onTableChecked([]);
        // 重新加载
        if (this.searchCom) {
            this.searchCom.submit();
        } else {
            this.tableCom.load();
        }
    }

    tableReset() {
        if (this.tableCom && this.tableCom.reset) {
            this.tableCom.reset();
        }
    }

    doSearch() {
        if (this.searchCom && this.searchCom.form) {
            this.searchCom.form.submit();
        }
    }

    onSearch(e) {
        // 原始搜索项
        let search = e;
        if (!this._table['params']) {
            this._table['params'] = {};
        }
        if (this._table['params']['search']) {
            let keys = Object.keys(this._table['params']['search']);
            keys.forEach(k => {
                search[k] = this._table['params']['search'][k];
            });
        }
        // 表单搜索项目
        let keys = Object.keys(e);
        keys.forEach(k => {
            if (!search[k]) {
                search[k] = e[k];
            }
        });

        // 加载数据
        this.tableCom.load({
            search: search,
            filter: this._table['params']['filter'] ? this._table['params']['filter'] : ''
        });
    }

    onTableChecked(e) {
        this._table['checked'] = e;
        // 按钮组中需要显示选中数量的按钮
        if (this.tools['actions']) {
            this.tools['actions'].forEach((action, index) => {
                if (action.hasOwnProperty('checked')) {
                    if (e.length) {
                        this.tools['actions'][index]['text_checked'] = this.tools['actions'][index].text + ' ' + e.length + ' 项';
                        this.tools['actions'][index].checked = false;
                        this.tools['actions'][index]['data'] = e;
                    } else {
                        this.tools['actions'][index]['text_checked'] = this.tools['actions'][index].text;
                        this.tools['actions'][index].checked = true;
                    }
                }
            });
        }
    }

    showFormModal() {
        this.form.show = true;
    }

    handleFormModalCancel() {
        this.formCom.reset();
        this.form.show = false;
    }

    handleFormModalOk() {
        this.formCom.submit();
    }

    handleViewModalCancel() {
        this.view.show = false;
    }

    onFormSubmit(e) {
        if (!this.form.loading) {
            this.form.loading = true;
            this.form.loadTip = '正在保存';
            for (let key in e) {
                this.transforms.forEach(element => {
                    if (element['field'] == key) {
                        e[key] = element['value'](e[key]);
                    }
                });
            }
            this.http[this.form.method](this.form.url, e).subscribe(res => {
                if (res.success) {
                    this.msg.success(this.form.title + '成功！');
                    this.handleFormModalCancel();
                    this.reload();
                } else {
                    this.msg.error(res.message);
                }
                this.form.loading = false;
            }, error => {
                this.msg.error(error);
                this.form.loading = false;
            });
        }
    }

    delete(id) {
        this.http.delete(this._config.delete.url + '?id=' + id).subscribe((res: MyResponse) => {
            if (res.success) {
                this.msg.success('删除成功');
                this.reload();
            } else {
                this.msg.error(res.message);
            }
        });
    }

    onDragSort(e) {
        if (this._config.sort.url) {
            this.ps.hasPermission(this._config.update.permission).then(v => {
                this.http.put(this._config.sort.url, e).subscribe((res: MyResponse) => {
                    if (res.success) {
                        this.reload();
                    } else {
                        this.msg.error(res.message);
                    }
                });
            });
        }
    }
}
