import {ControlBase} from './control-base';

export class ControlRadio extends ControlBase<string> {
    ControlType = 'radio';

    options: any[] = [];
    labelProperty: string = 'text';
    valueProperty: string = 'id';

    constructor(options: {} = {}) {
        super(options);
        this.value = (undefined != options['value']) ? options['value'] : null;
        this.options = options['options'] || this.options;
        this.labelProperty = options['labelProperty'] || this.labelProperty;
        this.valueProperty = options['valueProperty'] || this.valueProperty;
    }
}
