import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {PageService} from '../../../../core/base/services/page.service';
import {Store} from '@ngrx/store';
import {AppStates, baseState} from '../../../../app.states';
import {map} from 'rxjs/operators';

@Component({
    selector: 'my-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit {

    breadcrumb = [];
    menu = {};

    constructor(
        private page: PageService,
        private store: Store<AppStates>
    ) {
    }

    ngOnInit() {
        this.store.select(baseState).pipe(
            map(state => state['pageInfo']),
            map(pageInfo => {
                this.breadcrumb = pageInfo['breadcrumb'];
                this.menu = pageInfo['menu'];
            })
        ).subscribe();
    }

    showTitle(menu) {
        return (undefined != menu && undefined != menu.data && undefined != menu.data.title) ? menu.data.title : (undefined != menu && undefined != menu.title ? menu.title : '');
    }
}
