// Angular
import {Injectable} from '@angular/core';
// RxJS
import {Router} from '@angular/router';
import {ConfigService} from './config.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {fromPromise} from 'rxjs/internal-compatibility';

@Injectable()
export class MenuService {

    constructor(
        private router: Router,
        private config: ConfigService,
        private ps: NgxPermissionsService,
    ) {
    }

    load() {
        return this.parseMenu(this.config.getMenu(), 0, []);
    }

    parseMenu(menus, level, path) {
        let _menus = [];
        level++;
        for (const menu of menus) {
            let _menu = {
                title: menu.title,
                icon: menu.icon ? menu.icon : '',
                page: menu.page ? this.splitPathToArray(menu.page) : [],
                permission: menu.permission,
                level: level
            };
            _menu['path'] = this.parsePath([path, ..._menu.page]);
            _menu['open'] = (this.router.url.indexOf(_menu['path']) == 0);
            if (undefined != menu.children && menu.children.length && !menu.leaf) {
                _menu['children'] = menu.children.length ? this.parseMenu(menu.children, level, _menu['path']) : [];
            }
            if (undefined != menu['permission']) {
                this.ps.hasPermission(menu['permission']).then(r => {
                    if (r) {
                        _menus.push(_menu);
                    }
                });
            } else {
                _menus.push(_menu);
            }
        }
        return _menus;
    }

    splitPathToArray(str) {
        let arr = ['/'];

        if (str.startsWith('./')) {
            str = str.substr(2);
        }

        if (str.startsWith('/')) {
            str = str.substr(1);
        }

        if (str.endsWith('/')) {
            str = str.substr(0, -1);
        }

        return [
            ...arr,
            ...str.split('/')
        ];
    }

    parsePath(page) {
        if (page.length && page.length) {
            let _page = [];
            page.forEach(p => {
                if ('/' != p) {
                    _page.push(p);
                }
            });
            let path = _page.join('/');
            return path.startsWith('//') ? path.substr(1) : path;
        } else {
            return '/error/403';
        }
    }
}
