import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
    selector: 'my-tool-bar',
    templateUrl: './tool-bar.component.html',
    styleUrls: ['./tool-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToolBarComponent implements OnInit {
    // 左侧标题
    @Input() title: string = '';

    // 按钮组
    @Input() set actions(_actions: {
        text: string,
        text_checked?: string,
        type?: string,
        icon?: string,
        checked?: boolean,
        loading?: boolean,
        confirm?: boolean,
        permission?: string,
        callback?: (any) => void,
        data?: any
    }[]) {
        this.checkPermission(_actions);
        this._actions = _actions;
    };

    // 菜单组
    @Input() set menus(_menus: {
        text: string,
        icon?: string,
        permission?: string,
        callback?: () => void
    }[]) {
        this.checkPermission(_menus);
        this._menus = _menus;
    };

    _actions: {
        text: string,
        text_checked?: string,
        type?: string,
        icon?: string,
        checked?: boolean,
        loading?: boolean,
        confirm?: boolean,
        permission?: string,
        callback?: (any) => void,
        data?: any
    }[] = [];

    _menus: {
        text: string,
        icon?: string,
        permission?: string,
        callback?: () => void
    }[] = [];

    constructor(
        private ps: NgxPermissionsService,
    ) {
    }

    ngOnInit() {
    }

    checkPermission(items: any[]) {
        items.forEach((item, index) => {
            if (undefined != item['permission']) {
                this.ps.hasPermission(item['permission']).then(v => {
                    if (!v) {
                        this._actions.splice(index, 1);
                    }
                });
            }
        });
    }
}
