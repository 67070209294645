import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BaseActionType, Navigated, PageInfo} from '../actions/base.actions';
import {ConfigService} from '../services/config.service';
import {PageService} from '../services/page.service';
import {Action} from '@ngrx/store';

@Injectable()
export class BaseEffects {
    constructor(
        private actions$: Actions,
        private config: ConfigService,
        private page: PageService
    ) {
    }

    @Effect()
    Navigated$: Observable<Action> = this.actions$.pipe(
        ofType<Navigated>(BaseActionType.NAVIGATED),
        map((action: Navigated) => action.payload),
        map(url => {
            return new PageInfo(this.page.buildPageInfo({
                url: url,
                menu: this.config.getMenu()
            }));
        }),
        catchError(error => {
            return of(error);
        })
    );
}
