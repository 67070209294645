import {ControlBase} from './control-base';

export class ControlText extends ControlBase<string> {
    ControlType = 'text';

    constructor(options: {} = {}) {
        super(options);
        this.value = (undefined != options['value']) ? options['value'] : '';
        this.placeholder = options['placeholder'] || '请输入' + (options['label'] || '');
    }
}
