// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingScreenService} from './base/services/loading-screen.service';
import {AuthService} from './auth/auth.service';
import {SecurityStorageService} from './base/services/security-storage.service';
import {HttpService} from './http/http.service';
import {PageService} from './base/services/page.service';
import {ConfigService} from './base/services/config.service';
import {MenuService} from './base/services/menu.service';
import {FormService} from './form/form.service';
import {ViewService} from './view/view.service';
import {TableService} from './table/table.service';
import {ObjectUtil} from './utils/object.util';
import {CastUtil} from './utils/cast.util';
import {KeysPipe} from './pipe/keys.pipe';
import {CurrPipe} from './pipe/curr.pipe';
import {DefaultPipe} from './pipe/default.pipe';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
    ],
    exports: [],
    providers: [
        LoadingScreenService,
        SecurityStorageService,
        AuthService,
        HttpService,
        ConfigService,
        MenuService,
        PageService,
        FormService,
        ViewService,
        TableService,
        ObjectUtil,
        CastUtil,
        KeysPipe,
        CurrPipe,
        DefaultPipe
    ]
})
export class CoreModule {
}
