import {ControlBase} from './control-base';
import {formatDate} from '@angular/common';

export class ControlDate extends ControlBase<string> {
    ControlType = 'date';

    time: boolean = false;
    today: boolean = false;
    format: string = 'yyyy-MM-dd';
    _value: Date = null;
    nzDisabled:boolean=false;

    constructor(options: {} = {}) {
        super(options);
        this.value = (undefined != options['value']) ? options['value'] : null;
        this._value = this.castValue(this.value);
        this.time = options['time'] || false;
        this.today = options['today'] || true;
        this.format = options['format'] || (this.time ? this.format + ' HH:mm:ss' : this.format);
        this.placeholder = options['placeholder'] || '请选择' + (options['label'] || '');
        this.nzDisabled=options['nzDisabled']||false
    }

    castValue(value) {
        if (value && 'string' == typeof value) {
            return new Date(value);
        }
        return new Date();
    }

    onChange(e, control) {
        if (e) {
            control.value = formatDate(e, control.format, 'zh-CN');
        }
    }

    refresh() {
        this._value = this.castValue(this.value);
    }
}
