import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation, ViewRef} from '@angular/core';
import {MenuService} from '../../../../core/base/services/menu.service';
import {ConfigService} from '../../../../core/base/services/config.service';

@Component({
    selector: 'my-sider',
    templateUrl: './sider.component.html',
    styleUrls: ['./sider.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SiderComponent implements OnInit, OnDestroy {

    @Input() isCollapsed = false;

    menus: any[] = [];


    constructor(
        private cdr: ChangeDetectorRef,
        private ms: MenuService,
        private config: ConfigService
    ) {
        this.menus = this.ms.load();
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.cdr.detach();
    }
}
