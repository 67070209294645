import {ControlBase} from './control-base';

export class ControlTree extends ControlBase<string> {
    ControlType = 'tree';

    url: string = '';
    loading: boolean = false;
    options: any[] = [];
    labelProperty: string = 'text';
    valueProperty: string = 'id';
    search: boolean = false;
    multiple: boolean = false;
    lazy: boolean = false;
    selected: null | 'first' | 'last' = null;
    change: any = (e: any) => {
    };

    constructor(options: {} = {}) {
        super(options);
        this.value = (undefined != options['value']) ? options['value'] : null;
        this.labelProperty = options['labelProperty'] || this.labelProperty;
        this.valueProperty = options['valueProperty'] || this.valueProperty;
        this.options = this.castOptions(options['options'] || this.options, 'key', 'title', this.valueProperty, this.labelProperty);
        this.search = options['search'] || this.search;
        this.multiple = options['multiple'] || this.multiple;
        this.url = options['url'] || this.url;
        this.loading = options['loading'] || this.loading;
        this.placeholder = options['placeholder'] || '请选择' + (options['label'] || '');
        this.selected = options['selected'] || this.selected;
        this.lazy = (undefined != options['lazy']) ? !!options['lazy'] : this.lazy;
        this.change = options['change'] || this.change;
    }

    // 刷新控件
    refresh() {
        this.options = this.castOptions(this.options, 'key', 'title', this.valueProperty, this.labelProperty);
    }
}
