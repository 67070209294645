import {ControlBase} from './control-base';

export class ControlTransfer extends ControlBase<string> {
    ControlType = 'transfer';
    remote = true;

    url: string = '';
    loading: boolean = false;
    options: any[] = [];
    leftTitle: string = '';
    rightTitle: string = '';
    leftActionText: string = '';
    rightActionText: string = '';
    labelProperty: string = 'text';
    valueProperty: string = 'id';
    lazy: boolean = false;
    search: boolean = false;
    style: any;

    constructor(options: {} = {}) {
        super(options);
        this.value = (undefined != options['value']) ? options['value'] : [];
        this.url = options['url'] || this.url;
        this.loading = options['loading'] || this.loading;
        this.options = this.castValue(options['options'], this.value);
        this.leftTitle = options['leftTitle'] || this.leftTitle;
        this.rightTitle = options['rightTitle'] || this.rightTitle;
        this.leftActionText = options['leftActionText'] || this.leftActionText;
        this.rightActionText = options['rightActionText'] || this.rightActionText;
        this.labelProperty = options['labelProperty'] || this.labelProperty;
        this.valueProperty = options['valueProperty'] || this.valueProperty;
        this.search = options['search'] || this.search;
        this.style = options['style'] || this.style;
        this.lazy = (undefined != options['lazy']) ? !!options['lazy'] : this.lazy;
    }

    // 穿梭框初始值处理
    castValue(options: any[], value) {
        value = this.parseValue(value);
        if (options) {
            options = this.castOptions(options || [], 'key', 'title', this.valueProperty, this.labelProperty);
            options.forEach((option, i) => {
                options[i]['direction'] = (value.indexOf(option[this.valueProperty]) < 0) ? 'left' : 'right';
                options[i]['checked'] = false;
            });
            options = [...options];
            return options;
        }
        return [];
    }

    // 穿梭框值处理
    onChange(control) {
        control.value = [];
        if (control.options) {
            control.options.forEach(option => {
                if ('right' == option.direction) {
                    control.value.push(option.key);
                }
            });
        }
    }

    parseValue(value) {
        return value.map(v => {
            return ('object' == typeof v) ? v[this.valueProperty] : v;
        });
    }

    // 刷新控件
    refresh() {
        this.options = this.castValue(this.options, this.value);
    }
}
