import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AppStates, authState} from '../../../../app.states';
import {Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import {HttpService} from '../../../../core/http/http.service';
import {UserInterface} from '../../../../core/auth/user.interface';
import {ConfigService} from '../../../../core/base/services/config.service';

@Component({
    selector: 'my-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {

    @Input() isCollapsed = false;
    @Output() isCollapsedChange: EventEmitter<Boolean> = new EventEmitter;

    user: UserInterface;

    scope = '';

    constructor(
        private router: Router,
        private store: Store<AppStates>,
        private http: HttpService,
        private config: ConfigService
    ) {
        this.store.select(authState).pipe(
            map((state: any) => state.user),
            map(user => {
                if (user) {
                    user['avatar_url'] = user['avatar_url'] ? this.http.url(user['avatar_url'], false) : 'assets/media/users/default.jpg';
                }
                return user;
            })
        ).subscribe(
            user => {
                this.user = user;
            }
        );
        this.scope = this.config.getName();
    }

}
