import {ControlBase} from './control-base';

export class ControlSlider extends ControlBase<string> {
    ControlType = 'slider';

    min: number = 1;
    max: number = 100;
    step: number = 1;

    constructor(options: {} = {}) {
        super(options);
        this.value = (undefined != options['value']) ? options['value'] : this.value;
        this.min = options['min'] || this.min;
        this.max = options['max'] || this.max;
        this.step = options['step'] || this.step;
    }
}
