import {ControlBase} from './control-base';
import {formatDate} from '@angular/common';

export class ControlYear extends ControlBase<string> {
    ControlType = 'year';

    format: string = 'yyyy';
    _value: Date = null;

    constructor(options: {} = {}) {
        super(options);
        this.value = (undefined != options['value']) ? options['value'] : null;
        this._value = this.castValue(this.value);
        this.format = options['format'] || this.format;
        this.placeholder = options['placeholder'] || '请选择' + (options['label'] || '');
    }

    castValue(value) {
        if (value && 'string' == typeof value) {
            return new Date(value);
        }
        return new Date();
    }

    onChange(e, control) {
        if (e) {
            control.value = formatDate(e, control.format, 'zh-CN');
        }
    }
}
