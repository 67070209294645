import {All, AuthActionType} from '../actions/auth.actions';

export interface State {
    isAuthenticated: boolean;
    user: any;
    errorMessage: string | null;
}

export const initialState: State = {
    isAuthenticated: false,
    user: null,
    errorMessage: null
};

export function reducer(state = initialState, action: All): State {
    switch (action.type) {
        case AuthActionType.LOGIN_SUCCESS: {
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
                errorMessage: null
            };
        }
        case AuthActionType.LOGIN_FAILURE: {
            return {
                ...state,
                errorMessage: action.payload
            };
        }
        case AuthActionType.LOGOUT_SUCCESS: {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                errorMessage: null
            };
        }
        default:
            return state;
    }
}
