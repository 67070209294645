// Angular
import {Injectable} from '@angular/core';
// RxJS
import {Router} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import {ConfigService} from './config.service';
import {Observable, of} from 'rxjs';

@Injectable()
export class PageService {

    event$: Observable<any> = null;

    private pages = {};

    constructor(
        private router: Router,
        private config: ConfigService
    ) {
    }

    buildPageInfo(res: {
        url: string,
        menu: any
    }) {
        let routerUrl = res['url'];
        let menus = res['menu'];
        let breadcrumb = [];
        let menu = {};
        const findPath = (menus: any[], urls: string[], path: string = '') => {
            urls.forEach(url => {
                if (menus) {
                    menus.forEach(_menu => {
                        if (_menu) {
                            let _page = _menu.page ? _menu.page : '';
                            if (_page) {
                                _page = _page.startsWith('./') ? _page.substr(2) : _page;
                                _page = _page.startsWith('/') ? _page.substr(1) : _page;
                                if (_page == url) {
                                    let _breadcrumb = {
                                        title: _menu.title,
                                        icon: _menu.icon ? _menu.icon : '',
                                        link: !_menu.children,
                                        url: path + '/' + url,
                                        params: _menu.params ? _menu.params : [],
                                    };
                                    breadcrumb.push(_breadcrumb);
                                    if (urls.length == 1 || _menu.leaf) {
                                        menu = _menu;
                                    }
                                    if (_menu.children) {
                                        findPath(_menu.children, urls.splice(1), _breadcrumb.url);
                                    }
                                }
                            }
                        }
                    });
                }
            });
        };
        // cache
        if (this.pages[routerUrl]) {
            return this.pages[routerUrl];
        } else {
            findPath(menus, routerUrl.substr(1).split('/'));
            return this.pages[routerUrl] = {
                url: routerUrl,
                breadcrumb: breadcrumb,
                menu: menu,
            };
        }
    }
}
