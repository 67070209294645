import {ColumnBase} from './column-base';
import {HttpService} from '../../http/http.service';
import {LocatorService} from '../../base/services/locator.service';

export class ColumnImage extends ColumnBase {
    ColumnType = 'image';

    http: HttpService = LocatorService.injector.get(HttpService);

    shape: 'square' | 'circle' = 'square';
    size: number = 32;

    constructor(options: {} = {}) {
        super(options);
        this.shape = (options['shape'] == undefined) ? this.shape : options['shape'];
        this.size = (options['size'] == undefined) ? this.size : options['size'];
    }

    url(url){
        if(typeof(url)=='string'){
            return this.http.url(url, false);
        }else{
            return ;
        }
    }
}
