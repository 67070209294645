import {ControlBase} from './control-base';
import {formatDate} from '@angular/common';

export class ControlRange extends ControlBase<string> {
    ControlType = 'range';

    time: boolean = false;
    today: boolean = false;
    format: string = 'yyyy-MM-dd';
    _value: Date[] = [];

    constructor(options: {} = {}) {
        super(options);
        this.value = (undefined != options['value']) ? options['value'] : null;
        this._value = this.castValue(this.value);
        this.time = options['time'] || false;
        this.today = options['today'] || true;
        this.format = options['format'] || (this.time ? this.format + ' HH:mm:ss' : this.format);
    }

    castValue(value) {
        if ('object' == typeof value && value && value.length == 2) {
            return [
                new Date(value[0]),
                new Date(value[1]),
            ]
        }
        return [
            new Date(),
            new Date(),
        ];
    }

    onChange(e, control) {
        if (e) {
            if ('object' == typeof e && e.length == 2) {
                control.value = [
                    formatDate(e[0], control.format, 'zh-CN'),
                    formatDate(e[1], control.format, 'zh-CN')
                ];
            } else {
                control.value = [];
            }
        }
    }
}
