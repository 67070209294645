// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

// theme component
import {BaseComponent} from './base/base.component';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {PartialsModule} from '../partials/partials.module';
import {ErrorPageComponent} from './error-page/error-page.component';


@NgModule({
	declarations: [
		BaseComponent,
        ErrorPageComponent
	],
    exports: [
        BaseComponent,
        ErrorPageComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgZorroAntdModule,
        PartialsModule
    ]
})
export class ThemeModule {
}
