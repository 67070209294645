// Angular
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HeaderComponent} from './layout/header/header.component';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {NgxPermissionsModule} from 'ngx-permissions';
import {BreadcrumbComponent} from './layout/breadcrumb/breadcrumb.component';
import {SiderComponent} from './layout/sider/sider.component';
import {SearchBarComponent} from './search-bar/search-bar.component';
import {TableComponent} from './table/table.component';
import {ToolBarComponent} from './tool-bar/tool-bar.component';
import {PageTableComponent} from './page-table/page-table.component';
import {FormComponent} from './form/form.component';
import {PageFormComponent} from './page-form/page-form.component';
import {ViewComponent} from './view/view.component';
import {PageTableCrudComponent} from './page-table-crud/page-table-crud.component';
import {MapComponent} from './map/map.component';
import {NgxAmapModule} from 'ngx-amap';
import {environment} from '../../../environments/environment';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {GoodsSpecsComponent} from './goods-specs/goods-specs.component';


@NgModule({
    entryComponents: [
        MapComponent,
        FormComponent
    ],
    declarations: [
        HeaderComponent,
        BreadcrumbComponent,
        SiderComponent,
        SearchBarComponent,
        TableComponent,
        ToolBarComponent,
        PageTableComponent,
        FormComponent,
        PageFormComponent,
        ViewComponent,
        PageTableCrudComponent,
        MapComponent,
        GoodsSpecsComponent,
    ],
    exports: [
        HeaderComponent,
        BreadcrumbComponent,
        SiderComponent,
        SearchBarComponent,
        TableComponent,
        ToolBarComponent,
        PageTableComponent,
        FormComponent,
        PageFormComponent,
        ViewComponent,
        PageTableCrudComponent,
        MapComponent,
        GoodsSpecsComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgZorroAntdModule,
        NgxPermissionsModule.forChild(),
        NgxAmapModule.forRoot({
            apiKey: environment.aMapApiKey
        }),
        DragDropModule,
    ],
})
export class PartialsModule {
}
