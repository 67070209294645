import {ControlBase} from './control-base';

export class ControlTextarea extends ControlBase<string> {
    ControlType = 'textarea';

    autoSize: {
        minRows: number,
        maxRows: number
    } = {
        minRows: 2,
        maxRows: 6
    };

    constructor(options: {} = {}) {
        super(options);
        this.value = (undefined != options['value']) ? options['value'] : '';
        this.autoSize = options['size'] || {
            minRows: this.autoSize.minRows,
            maxRows: this.autoSize.maxRows
        };
        this.placeholder = options['placeholder'] || '请输入' + (options['label'] || '');
    }
}
