import {ControlBase} from './control-base';

export class ControlSwitch extends ControlBase<string> {
    ControlType = 'switch';

    checkedLabel: string = '';
    unCheckedLabel: string = '';

    change: any = null;

    constructor(options: {} = {}) {
        super(options);
        this.value = (undefined != options['value']) ? !!options['value'] : false;
        this.checkedLabel = options['checkedLabel'] || this.checkedLabel;
        this.unCheckedLabel = options['unCheckedLabel'] || this.unCheckedLabel;
        this.change = options['change'] || this.change;

    }

    // 开关值处理
    onChange(e, control) {
        control.value = !!e;
        this.change && this.change(control.value);
    }

    // 刷新控件
    refresh() {
        this.value = !!this.value;
    }
}
